'use client';

import {
    createHydrateClient,
    createTRPCNextBeta,
} from '@halfwork.cn/next-layout/client';
import type { AppRouter } from '@/trpc-router/server/routers/v1/trpc-router'
import {createTRPCProxyClient, httpBatchLink, loggerLink} from '@trpc/client';
import superjson from 'superjson';

const getBaseUrl = () => (typeof window !== 'undefined' ? '' : `http://localhost:3005`)

export const trpcClient = createTRPCNextBeta<AppRouter>({
    transformer: superjson,
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchInterval: false,
                retry: false,
                cacheTime: Infinity,
                staleTime: Infinity,
            },
        },
    },
    links: [
        loggerLink({
            enabled: (opts) =>
                process.env.NODE_ENV === 'development' ||
                (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
        }),
    ],
});

export const trpcVanilla = createTRPCProxyClient<AppRouter>({
    links: [
        httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
        }),
    ],
    transformer: superjson,
})

export const HydrateClient = createHydrateClient({
    transformer: superjson,
});

