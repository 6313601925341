import(/* webpackMode: "eager" */ "/home/leikang/halfview/apps/workspace/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/leikang/halfview/apps/workspace/components/analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/leikang/halfview/apps/workspace/components/next-auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/leikang/halfview/apps/workspace/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitcher"] */ "/home/leikang/halfview/apps/workspace/components/theme-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/leikang/halfview/apps/workspace/contexts/session-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/leikang/halfview/apps/workspace/contexts/trpc-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/leikang/halfview/apps/workspace/public/registry/themes.css");
;
import(/* webpackMode: "eager" */ "/home/leikang/halfview/apps/workspace/registry/default/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/leikang/halfview/node_modules/.pnpm/next-intl@3.23.5_next@14.2.16_react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/leikang/halfview/node_modules/.pnpm/next@14.2.16_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
